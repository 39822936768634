var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Splide } from "@splidejs/splide";
export var CarouselVariant;
(function (CarouselVariant) {
    CarouselVariant["STANDARD"] = "standard";
    CarouselVariant["DENSE"] = "dense";
    CarouselVariant["HERO"] = "hero";
    CarouselVariant["SINGLE"] = "single";
})(CarouselVariant || (CarouselVariant = {}));
/**
 * Manages progressively enhancing lists to carousels
 */
var CarouselHandler = /** @class */ (function () {
    function CarouselHandler(props) {
        this.items = [];
        this.wrapper = props.wrapper;
        this.variantId = props.variantId;
        this.options = props.options || {
            type: "slide",
            speed: 1000,
            perPage: 3,
            perMove: 1,
            pagination: false,
            gap: "16px",
            autoplay: false,
            lazyLoad: false,
            role: "group"
        };
        this.classNames = props.classNames || {
            list: 'splide__list',
            navigationArrows: 'splide__arrows',
            paginationData: 'splide__pagination-data',
            controls: 'splide__controls',
        };
        this.init();
    }
    CarouselHandler.prototype.toggleNav = function (perPage) {
        var _a, _b, _c, _d;
        var shouldRenderNav = this.items.length > perPage;
        if (this.navigationArrows) {
            if (shouldRenderNav) {
                this.navigationArrows.removeAttribute('hidden');
                if (this.variantId !== CarouselVariant.HERO) {
                    (_a = this.paginationData) === null || _a === void 0 ? void 0 : _a.removeAttribute('hidden');
                    this.wrapper.style.paddingBottom = '60px';
                }
                if (this.variantId === CarouselVariant.HERO) {
                    (_b = this.controls) === null || _b === void 0 ? void 0 : _b.removeAttribute('hidden');
                }
            }
            else {
                this.navigationArrows.setAttribute('hidden', 'hidden');
                if (this.variantId !== CarouselVariant.HERO) {
                    (_c = this.paginationData) === null || _c === void 0 ? void 0 : _c.setAttribute('hidden', 'hidden');
                    this.wrapper.style.paddingBottom = '';
                }
                if (this.variantId === CarouselVariant.HERO) {
                    (_d = this.controls) === null || _d === void 0 ? void 0 : _d.setAttribute('hidden', 'hidden');
                }
            }
        }
    };
    CarouselHandler.prototype.setPaginationData = function (_a) {
        var activeSlideIndex = _a.activeSlideIndex;
        var itemCount = this.items.length;
        var startItem = activeSlideIndex + 1;
        var currentPerPage = this.instance ? this.instance.options.perPage : this.options.perPage;
        var endItem = startItem + (currentPerPage - 1);
        if (currentPerPage === 1) {
            this.paginationData.innerText = "".concat(startItem, " of ").concat(itemCount);
        }
        else {
            this.paginationData.innerText = "".concat(startItem, "-").concat(endItem, " of ").concat(itemCount);
        }
    };
    CarouselHandler.prototype.getVariantId = function () {
        return this.variantId;
    };
    CarouselHandler.prototype.init = function () {
        var _this = this;
        var _a, _b;
        this.navigationArrows = this.wrapper.querySelector(".".concat(this.classNames.navigationArrows));
        this.paginationData = (_a = this.wrapper) === null || _a === void 0 ? void 0 : _a.querySelector(".".concat(this.classNames.paginationData));
        this.controls = (_b = this.wrapper) === null || _b === void 0 ? void 0 : _b.querySelector(".".concat(this.classNames.controls));
        this.list = this.wrapper.querySelector(".".concat(this.classNames.list));
        this.list.style.display = "flex";
        this.list.style.flexDirection = "row";
        this.list.style.gap = "0px";
        if (this.list && this.list.children) {
            this.items = Array.from(this.list.children);
            if (this.items.length) {
                if (this.paginationData) {
                    this.setPaginationData({ activeSlideIndex: 0 });
                }
                this.toggleNav(this.options.perPage);
                this.instance = new Splide(this.wrapper, this.options);
                this.instance.mount();
                this.instance.on('moved', function (newIndex) {
                    if (_this.paginationData) {
                        _this.setPaginationData({ activeSlideIndex: newIndex });
                    }
                });
            }
        }
    };
    CarouselHandler.prototype.updateOptions = function (options) {
        if (this.instance) {
            this.instance.options = __assign(__assign({}, this.instance.options), options);
            if (this.options.perPage !== options.perPage) {
                this.instance.go(0);
                if (this.paginationData) {
                    this.setPaginationData({ activeSlideIndex: 0 });
                }
            }
        }
        this.toggleNav(options.perPage);
    };
    CarouselHandler.prototype.refresh = function () {
        var _a;
        (_a = this.instance) === null || _a === void 0 ? void 0 : _a.refresh();
    };
    return CarouselHandler;
}());
export { CarouselHandler };
export default CarouselHandler;
